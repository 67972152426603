var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-flex justify-content-between align-items-center mb-4"},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"arrow-left-circle","role":"button","size":"2rem"},on:{"click":_vm.back}}),_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)],1)]),_c('b-col',{staticClass:"d-flex align-items-stretch",staticStyle:{"min-height":"70vh"},attrs:{"cols":"12","lg":"12"}},[_c('b-card',{staticClass:"mb-4 w-100"},[_c('div',[_c('b-form',{staticClass:"p-5"},[_c('b-form',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}]},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('label',[_vm._v("Número documento")])]),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('b-form-input',{attrs:{"id":"prefijo","disabled":"","state":_vm.validateState('consecutivo')},model:{value:(
                                        _vm.factura_venta.consecutivo.prefijo
                                    ),callback:function ($$v) {_vm.$set(_vm.factura_venta.consecutivo, "prefijo", $$v)},expression:"\n                                        factura_venta.consecutivo.prefijo\n                                    "}}),(
                                        _vm.$v.factura_venta.consecutivo.$error
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.factura_venta.numero),expression:"factura_venta.numero"}],staticClass:"form-control",attrs:{"id":"numero_factura","type":"number","disabled":""},domProps:{"value":(_vm.factura_venta.numero)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.factura_venta, "numero", $event.target.value)}}})])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"cliente_id"}},[_vm._v("Establecimiento de comercio")]),_c('jautocomplete',{ref:"cliente_id",attrs:{"id":"cliente_id","items":_vm.clientes,"item-text":"nombre_comercial","item-value":"id","load-async":"","open-on-focus":"","state":_vm.validateState('cliente_id'),"disabled":_vm.read_only,"show-create-button":"","create-method":() => {
                                            _vm.$bvModal.show(
                                                'create-establecimiento-comercio-modal'
                                            )
                                        }},on:{"text":_vm.filterCliente,"change":_vm.assignCliente},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.getLabelCliente(item)))])]}}]),model:{value:(
                                        _vm.$v.factura_venta.cliente_id.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.cliente_id, "$model", $$v)},expression:"\n                                        $v.factura_venta.cliente_id.$model\n                                    "}}),(
                                        _vm.$v.factura_venta.cliente_id.$error
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"fecha"}},[_vm._v("Fecha")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"fecha","type":"text","placeholder":"AAAA-MM-DD","state":_vm.validateState('fecha'),"disabled":_vm.read_only,"autocomplete":"null"},model:{value:(
                                            _vm.$v.factura_venta.fecha.$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.fecha, "$model", $$v)},expression:"\n                                            $v.factura_venta.fecha.$model\n                                        "}}),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","right":"","locale":"en-US","state":_vm.validateState('fecha'),"aria-controls":"fecha","disabled":_vm.read_only},model:{value:(
                                                _vm.$v.factura_venta.fecha
                                                    .$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.fecha
                                                    , "$model", $$v)},expression:"\n                                                $v.factura_venta.fecha\n                                                    .$model\n                                            "}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.factura_venta.fecha.formatDate}},[_vm._v(" Formato de fecha inválido ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.factura_venta.fecha.compareDates}},[_vm._v(" La fecha no puede ser mayor al vencimiento ")])],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"plazo"}},[_vm._v("Plazo (días)")]),_c('b-form-input',{attrs:{"id":"plazo","disabled":!_vm.$v.factura_venta.cliente_id
                                            .$model || _vm.read_only,"type":"number","state":_vm.validateStateSingle(
                                            'plazo_vencimiento'
                                        ),"autocomplete":"null"},on:{"input":_vm.assignVencimiento},model:{value:(_vm.$v.plazo_vencimiento.$model),callback:function ($$v) {_vm.$set(_vm.$v.plazo_vencimiento, "$model", $$v)},expression:"$v.plazo_vencimiento.$model"}}),(
                                        !_vm.$v.plazo_vencimiento
                                            .noNegativeNumbers
                                    )?_c('b-form-invalid-feedback',[_vm._v(" El plazo no puede ser negativo ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"vencimiento"}},[_vm._v("Vencimiento")]),_c('b-input-group',[_c('b-form-input',{attrs:{"id":"vencimiento","type":"text","placeholder":"AAAA-MM-DD","disabled":"","autocomplete":"null"},model:{value:(
                                            _vm.$v.factura_venta.vencimiento
                                                .$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.vencimiento
                                                , "$model", $$v)},expression:"\n                                            $v.factura_venta.vencimiento\n                                                .$model\n                                        "}})],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.factura_venta.vencimiento
                                            .formatDate}},[_vm._v(" Formato de fecha inválido ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$v.factura_venta.vencimiento
                                            .compareDates}},[_vm._v(" El vencimiento de la factura no puede ser mayor a la fecha ")])],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('label',{attrs:{"for":"vendedor_id"}},[_vm._v("Vendedor")]),_c('jautocomplete',{attrs:{"id":"vendedor_id","items":_vm.vendedores,"item-text":"descripcion","item-value":"id","open-on-focus":"","disabled":_vm.vendedores.length === 0 || _vm.read_only,"show-create-button":"","create-method":() => {
                                            _vm.$bvModal.show(
                                                'create-vendedor-modal'
                                            )
                                        },"state":_vm.validateState('vendedor_id')},model:{value:(
                                        _vm.$v.factura_venta.vendedor_id.$model
                                    ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.vendedor_id, "$model", $$v)},expression:"\n                                        $v.factura_venta.vendedor_id.$model\n                                    "}}),(
                                        _vm.$v.factura_venta.vendedor_id.$error
                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"nota"}},[_vm._v("Nota")]),_c('b-input-group',[_c('b-form-textarea',{attrs:{"id":"nota","size":"sm","disabled":_vm.read_only,"rows":"1","placeholder":"Alguna anotación?..."},model:{value:(
                                            _vm.$v.factura_venta.nota.$model
                                        ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.nota, "$model", $$v)},expression:"\n                                            $v.factura_venta.nota.$model\n                                        "}})],1),(!_vm.$v.factura_venta.nota.$maxLength)?_c('div',{staticClass:"length-field mt-1"},[_vm._v(" ("+_vm._s(_vm.$v.factura_venta.nota.$model.length)+" / "+_vm._s(_vm.$v.factura_venta.nota.$params .maxLength.max)+") ")]):_vm._e(),(!_vm.$v.factura_venta.nota.maxLength)?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.factura_venta.nota.$params .maxLength.max)+" caracteres ")]):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-block d-sm-flex justify-content-between mb-5"},[_c('label',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Productos")]),_c('div',[_c('b-button',{staticClass:"d-flex align-items-center mt-4 mt-sm-0",attrs:{"variant":"primary","size":"md"},on:{"click":function($event){return _vm.$bvModal.show(
                                                    'modal-detalle-factura'
                                                )}}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"eye","size":"1rem"}}),_vm._v(" Detalles de retenciones ")],1),_c('detalleFacturaVue',{attrs:{"details":_vm.detalle_factura}})],1)]),_c('b-row',{staticClass:"my-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                'ctrl+b ó cmd+b'
                                            ),expression:"\n                                                'ctrl+b ó cmd+b'\n                                            ",modifiers:{"hover":true,"top":true}}],ref:"itemsButton",staticClass:"d-flex align-items-center mt-4 mt-sm-0",attrs:{"variant":"primary","size":"md","disabled":_vm.read_only},on:{"click":function($event){return _vm.$bvModal.show('modal-items')}}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"search","size":"1rem"}}),_vm._v(" Más items ")],1)],1)],1),_c('b-table',{attrs:{"small":"","hover":"","responsive":"","foot-clone":"","striped":"","items":_vm.factura_venta.productos,"fields":_vm.titulosProductos},scopedSlots:_vm._u([{key:"cell(referencia)",fn:function(data){return [(!data.item.item_id)?_c('jautocomplete',{attrs:{"id":`referencia-${data.item.item_id}`,"items":_vm.productos,"item-text":"referencia","item-value":"id","return-object":"","load-async":"","open-on-focus":"","disabled":_vm.read_only,"show-create-button":"","create-method":() => {
                                                    _vm.$bvModal.show(
                                                        'create-item-modal'
                                                    )
                                                }},on:{"text":_vm.filterProductos,"change":_vm.insertProducto}}):_c('span',[_vm._v(_vm._s(data.value))])]}},{key:"cell(descripcion)",fn:function(data){return [(!data.item.item_id)?_c('jautocomplete',{attrs:{"id":`producto-${data.item.item_id}`,"items":_vm.productos,"item-text":"descripcion","item-value":"id","return-object":"","load-async":"","open-on-focus":"","disabled":_vm.read_only,"show-create-button":"","create-method":() => {
                                                    _vm.$bvModal.show(
                                                        'create-item-modal'
                                                    )
                                                }},on:{"text":_vm.filterProductos,"change":_vm.insertProducto},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.getLabelItem(item)))])]}}],null,true)}):_vm._e(),(
                                                data.item.item_id !==
                                                    null &&
                                                data.item.item_id !==
                                                    undefined
                                            )?_c('b-form-input',{attrs:{"type":"text","id":`producto-${data.item.item_id}`,"disabled":_vm.read_only ||
                                                !data.item.item_id,"state":_vm.validateStateProducts(
                                                    data.index,
                                                    'descripcion'
                                                )},model:{value:(
                                                _vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .descripcion.$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .descripcion, "$model", $$v)},expression:"\n                                                $v.factura_venta.productos\n                                                    .$each[data.index]\n                                                    .descripcion.$model\n                                            "}}):_vm._e(),(
                                                !_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .descripcion.maxLength
                                            )?_c('b-form-invalid-feedback',[_vm._v(" Este campo debe tener máximo "+_vm._s(_vm.$v.factura_venta.productos .$each[data.index] .descripcion.$params .maxLength.max)+" caracteres ")]):_vm._e(),(
                                                !_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .descripcion.required
                                            )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e()]}},{key:"cell(precio_base_venta)",fn:function(data){return [_c('currency-input',{attrs:{"id":`precio-base-venta-producto-${data.item.item_id}`,"disabled":_vm.read_only ||
                                                !data.item.item_id ||
                                                !_vm.permisos.includes(
                                                    'cotizacion_cliente-modificar-precio'
                                                ),"state":_vm.validateStateProducts(
                                                    data.index,
                                                    'precio_base_venta'
                                                )},on:{"focus":_vm.onFocused},model:{value:(
                                                _vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .precio_base_venta
                                                    .$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .precio_base_venta
                                                    , "$model", _vm._n($$v))},expression:"\n                                                $v.factura_venta.productos\n                                                    .$each[data.index]\n                                                    .precio_base_venta\n                                                    .$model\n                                            "}})]}},{key:"cell(valor)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.calcValor(data).toFixed(2)))+" ")]}},{key:"cell(cantidad)",fn:function(data){return [_c('b-form-input',{attrs:{"id":`cantidad-producto-${data.item.item_id}`,"disabled":_vm.read_only ||
                                                !data.item.item_id,"type":"number","state":_vm.validateStateProducts(
                                                    data.index,
                                                    'cantidad'
                                                ),"autocomplete":"null"},on:{"focus":_vm.onFocused},model:{value:(
                                                _vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .cantidad.$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .cantidad, "$model", $$v)},expression:"\n                                                $v.factura_venta.productos\n                                                    .$each[data.index]\n                                                    .cantidad.$model\n                                            "}}),(
                                                !_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .cantidad.required
                                            )?_c('b-form-invalid-feedback',[_vm._v(" Campo requerido ")]):_vm._e(),(
                                                !_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .cantidad.maxLength
                                            )?_c('b-form-invalid-feedback',[_vm._v(" Máximo "+_vm._s(_vm.$v.factura_venta.productos .$each[data.index] .cantidad.$params .maxLength.max)+" caracteres ")]):_vm._e()]}},{key:"cell(porcentaje_descuento)",fn:function(data){return [_c('b-form-input',{attrs:{"id":`porcentaje-descuento-producto-${data.item.item_id}`,"type":"number","placeholder":"Ej: 19","disabled":_vm.read_only ||
                                                !data.item.item_id || !_vm.permisos.includes('cotizacion_cliente-modificar-descuento'),"state":_vm.validateStateProducts(
                                                    data.index,
                                                    'porcentaje_descuento'
                                                )},on:{"focus":_vm.onFocused,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.goToNextField('productos')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.goToNextField('productos')}]},model:{value:(
                                                _vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .porcentaje_descuento
                                                    .$model
                                            ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .porcentaje_descuento
                                                    , "$model", $$v)},expression:"\n                                                $v.factura_venta.productos\n                                                    .$each[data.index]\n                                                    .porcentaje_descuento\n                                                    .$model\n                                            "}}),(
                                                !_vm.$v.factura_venta.productos
                                                    .$each[data.index]
                                                    .porcentaje_descuento
                                                    .maxLength
                                            )?_c('b-form-invalid-feedback',[_vm._v(" Máximo "+_vm._s(_vm.$v.factura_venta.productos .$each[data.index] .porcentaje_descuento .$params.maxLength.max)+" caracteres ")]):_vm._e()]}},{key:"cell(total_iva)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.tarifa_iva.porcentaje)+"% ")]}},{key:"cell(total_impoconsumo)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.calcTotalImpoconsumo(data).toFixed(2) ))+" ")]}},{key:"cell(total)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.calcTotal(data).toFixed(2)))+" ")]}},{key:"cell(acciones)",fn:function(row){return [(
                                                _vm.read_only ||
                                                !row.item.item_id
                                            )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                'Eliminar producto'
                                            ),expression:"\n                                                'Eliminar producto'\n                                            ",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary",attrs:{"type":"trash-2","size":"1.3rem"}}):_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                'Eliminar producto'
                                            ),expression:"\n                                                'Eliminar producto'\n                                            ",modifiers:{"hover":true,"top":true}}],staticClass:"text-error",attrs:{"type":"trash-2","size":"1.3rem","role":"button"},on:{"click":function($event){return _vm.confirmRemoveItem(row)}}}),_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                row.detailsShowing
                                                    ? 'Ocultar detalles'
                                                    : 'Ver detalles'
                                            ),expression:"\n                                                row.detailsShowing\n                                                    ? 'Ocultar detalles'\n                                                    : 'Ver detalles'\n                                            ",modifiers:{"hover":true,"top":true}}],staticClass:"text-secondary ml-2",attrs:{"type":row.detailsShowing
                                                    ? 'arrow-up-circle'
                                                    : 'arrow-down-circle',"size":"1.3rem","role":"button"},on:{"click":($evt) => {
                                                    row.toggleDetails($evt)
                                                    _vm.setBodegaAndLote(
                                                        row.item
                                                    )
                                                }}})]}},{key:"foot()",fn:function(data){return [(
                                                data.field.key ===
                                                'referencia'
                                            )?_c('div',[_c('b',[_vm._v("Subtotal")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.subtotal_neto.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'descripcion'
                                            )?_c('div',[_c('b',[_vm._v("Descuento")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.descuento.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'porcentaje_descuento'
                                            )?_c('div',[_c('b',[_vm._v("Iva")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.total_iva.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'cantidad'
                                            )?_c('div',[_c('b',[_vm._v("Impoconsumo")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.totalesFacturas.total_ipoconsumo.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key === 'valor'
                                            )?_c('div',[_c('b',[_vm._v("Retefuente")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.total_retefuente.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'precio_base_venta'
                                            )?_c('div',[_c('b',[_vm._v("Reteiva")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.total_reteiva.toFixed(2) ))+" ")])]):_vm._e(),(
                                                data.field.key ===
                                                'acciones'
                                            )?_c('div',[_c('b',[_vm._v("Total")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( _vm.granTotal.toFixed(2) ))+" ")])]):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-row',{staticClass:"pa-2"},[_c('b-col',{attrs:{"cols":"2"}},[_c('b',[_vm._v("Descuento")]),_c('div',[_vm._v(" "+_vm._s(_vm.currencyFormat( row.item .descuento.toFixed(2) ))+" ")])]),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"bodega_id"}},[_vm._v("Bodega")]),_c('jautocomplete',{attrs:{"id":"bodega_id","items":_vm.bodegas,"item-text":"descripcion","item-value":"id","open-on-focus":"","disabled":_vm.read_only ||
                                                            !row.item
                                                                .item_id || !_vm.permisos.includes('cotizacion_cliente-modificar-bodega')},model:{value:(
                                                            row.item
                                                                .bodega_id
                                                        ),callback:function ($$v) {_vm.$set(row.item
                                                                , "bodega_id", $$v)},expression:"\n                                                            row.item\n                                                                .bodega_id\n                                                        "}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"lote_inventario_id"}},[_vm._v("Lote de inventario")]),_c('jautocomplete',{attrs:{"id":"lote_inventario_id","items":_vm.lotes_inventario,"item-text":"nombre","item-value":"id","open-on-focus":"","disabled":_vm.read_only ||
                                                            !row.item
                                                                .item_id || !_vm.permisos.includes('cotizacion_cliente-modificar-lote')},model:{value:(
                                                            row.item
                                                                .lote_inventario_id
                                                        ),callback:function ($$v) {_vm.$set(row.item
                                                                , "lote_inventario_id", $$v)},expression:"\n                                                            row.item\n                                                                .lote_inventario_id\n                                                        "}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"tercero_id"}},[_vm._v("Tercero")]),_c('jautocomplete',{attrs:{"id":"tercero_id","items":_vm.tercerosCopy,"item-value":"id","item-text":"n_documento","custom-filter":_vm.filterTerceros,"open-on-focus":"","custom-text-field":_vm.textFieldTercero,"show-create-button":"","disabled":_vm.read_only ||
                                                            !row.item.item_id ||
                                                            !_vm.permisos.includes('cotizacion_cliente-modificar-tercero')},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('div',{staticClass:"p-1 option",attrs:{"role":"button"}},[_vm._v(" "+_vm._s(item.tipo_documento_id === '31' ? item.razon_social : `${item.nombre1} ${item.apellido1}`)+" ")])]}}],null,true),model:{value:(row.item.tercero_id),callback:function ($$v) {_vm.$set(row.item, "tercero_id", $$v)},expression:"row.item.tercero_id"}})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"concepto_contable_id"}},[_vm._v("Concepto contable")]),_c('jautocomplete',{attrs:{"id":"concepto_contable_id","items":_vm.conceptos_contables,"item-text":"descripcion","item-value":"id","open-on-focus":"","disabled":_vm.read_only ||
                                                            !row.item
                                                                .item_id || !_vm.permisos.includes('cotizacion_cliente-modificar-concepto-contable')},model:{value:(row.item.concepto_contable_id),callback:function ($$v) {_vm.$set(row.item, "concepto_contable_id", $$v)},expression:"row.item.concepto_contable_id"}})],1)],1)],1)]}}])}),(_vm.productsLengthError)?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" La lista de productos debe tener al menos "+_vm._s(_vm.$v.factura_venta.productos.$params .minLength.min)+" producto ")]):_vm._e()],1)],1),(_vm.plazo_vencimiento == 0)?_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Formas de pago")]),_vm._l((_vm.factura_venta.formas_pago),function(item,index){return _c('div',{key:index,staticClass:"my-3"},[_c('b-row',{staticClass:"d-flex flex-row align-items-center"},[_c('b-col',{attrs:{"cols":"12","sm":"5"}},[_c('b-input-group',[_c('jautocomplete',{attrs:{"id":"forma_pago","items":_vm.formas_pago,"item-value":"id","item-text":"descripcion","open-on-focus":"","state":_vm.validateStateFormasPago(
                                                            index,
                                                            'forma_pago_id'
                                                        ),"disabled":_vm.read_only},model:{value:(
                                                        _vm.$v.factura_venta
                                                            .formas_pago
                                                            .$each[index]
                                                            .forma_pago_id
                                                            .$model
                                                    ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta
                                                            .formas_pago
                                                            .$each[index]
                                                            .forma_pago_id
                                                            , "$model", $$v)},expression:"\n                                                        $v.factura_venta\n                                                            .formas_pago\n                                                            .$each[index]\n                                                            .forma_pago_id\n                                                            .$model\n                                                    "}}),(
                                                        !_vm.$v.factura_venta
                                                            .formas_pago
                                                            .$each[index]
                                                            .forma_pago_id
                                                            .required &&
                                                        _vm.$v.factura_venta
                                                            .formas_pago
                                                            .$each[index]
                                                            .forma_pago_id
                                                            .$error
                                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e(),(
                                                        !_vm.$v.factura_venta
                                                            .formas_pago
                                                            .$each[index]
                                                            .forma_pago_id
                                                            .noRepeat
                                                    )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" No se admiten duplicados ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"5"}},[_c('currencyInput',{attrs:{"state":_vm.validateStateFormasPago(
                                                        index,
                                                        'value'
                                                    ),"disabled":_vm.read_only},model:{value:(
                                                    _vm.$v.factura_venta
                                                        .formas_pago.$each[
                                                        index
                                                    ].value.$model
                                                ),callback:function ($$v) {_vm.$set(_vm.$v.factura_venta
                                                        .formas_pago.$each[
                                                        index
                                                    ].value, "$model", $$v)},expression:"\n                                                    $v.factura_venta\n                                                        .formas_pago.$each[\n                                                        index\n                                                    ].value.$model\n                                                "}}),(
                                                    !_vm.$v.factura_venta
                                                        .formas_pago.$each[
                                                        index
                                                    ].value.minValue &&
                                                    _vm.$v.factura_venta
                                                        .formas_pago.$each[
                                                        index
                                                    ].value.$error
                                                )?_c('div',{staticClass:"invalid-field mt-1"},[_vm._v(" Campo requerido ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","sm":"2"}},[_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                    'Agregar'
                                                ),expression:"\n                                                    'Agregar'\n                                                ",modifiers:{"hover":true,"top":true}}],attrs:{"type":"plus","role":"button"},on:{"click":function($event){_vm.read_only
                                                        ? (() => {})()
                                                        : _vm.addFormaPago()}}}),(
                                                    _vm.factura_venta
                                                        .formas_pago
                                                        .length > 1
                                                )?_c('feather',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                                                    'Eliminar'
                                                ),expression:"\n                                                    'Eliminar'\n                                                ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-2",attrs:{"type":"trash-2","role":"button"},on:{"click":function($event){_vm.read_only
                                                        ? (() => {})()
                                                        : _vm.deleteFormaPago(
                                                              index
                                                          )}}}):_vm._e()],1)],1)],1)})],2),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Cambio:")]),_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.cambio))+" ")])],1):_vm._e()],1)],1),_c('prefijoModal',{attrs:{"consecutivos":_vm.consecutivos},on:{"selectedConsecutivo":_vm.setConsecutivo}})],1)])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('div',{staticClass:"d-flex flex-row"},[_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Presione esc'),expression:"'Presione esc'",modifiers:{"hover":true,"top":true}}],staticClass:"mr-4",attrs:{"variant":"secondary","disabled":_vm.isLoading,"size":"md"},on:{"click":_vm.back}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                        'Presione ctrl+shift+s o cmd+shift+s'
                    ),expression:"\n                        'Presione ctrl+shift+s o cmd+shift+s'\n                    ",modifiers:{"hover":true,"top":true}}],staticClass:"d-flex align-items-center",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.read_only,"size":"md"},on:{"click":_vm.save}},[_c('feather',{staticClass:"mr-2 text-blue-active",attrs:{"type":"save","size":"1rem"}}),(_vm.isLoading)?_c('span',{staticClass:"d-sm-flex align-items-center justify-content-center"},[_c('b-spinner',{attrs:{"small":""}}),_c('span',{staticClass:"ml-2"},[_vm._v("Guardando...")])],1):_c('span',[_vm._v("Guardar")])],1)],1)])]),_c('crearVendedoresModalVue',{on:{"newVendedor":_vm.setNewVendedor}}),_c('crearEstablecimientoComercioModalVue',{on:{"newEstablecimientoComercio":_vm.setNewEstablecimientoComercio}}),_c('crearItemVue',{on:{"newItem":_vm.setNewItem}}),_c('modalItem',{on:{"selectedItem":_vm.insertProducto}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }